import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';

const Dsgvo = () => import('@/core/dsgvo/dsgvo.vue');
const Landing = () => import('@/core/landing/landing.vue');
const Music = () => import('@/core/music/music.vue');
const Video = () => import('@/core/video/video.vue');
const Home = () => import('@/core/home/home.vue');
const Error = () => import('@/core/error/error.vue');
import account from '@/router/account';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';

Vue.use(Router);

// prettier-ignore
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Landing',
      meta: {bg: "url('../../../content/images/landing.jpg')"},
      component: Landing
    },
    {
      path: '/music',
      name: 'Music',
      meta: {bg: "url('../../../content/images/music.jpg')"},
      component: Music
    },
    {
      path: '/impressum-dsgvo',
      name: 'Dsgvo',
      meta: {bg: "url('../../../content/images/dsgvo.jpg')"},
      component: Dsgvo
    },
    {
      path: '/video',
      name: 'Video',
      meta: {bg: "url('../../../content/images/video.jpg')"},
      component: Video
    },
    {
      path: '/home',
      name: 'Home',
      component: Home
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    ...account,
    ...admin,
    entities,
    ...pages
  ]
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.body.style.backgroundColor = to.meta.bg != null ? 'black' : 'white'; // to avoid ugly flimmering
    document.body.style.backgroundImage = to.meta.bg ?? '';
  });
});

export default router;
