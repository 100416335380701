var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer", attrs: { id: "footer" } },
    [
      _c("b-link", {
        attrs: { to: "impressum-dsgvo" },
        domProps: { textContent: _vm._s(_vm.$t("link-dsgvo")) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }