import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Publication = () => import('@/entities/publication/publication.vue');
const PublicationUpdate = () => import('@/entities/publication/publication-update.vue');
const PublicationDetails = () => import('@/entities/publication/publication-details.vue');

const Image = () => import('@/entities/image/image.vue');
const ImageUpdate = () => import('@/entities/image/image-update.vue');
const ImageDetails = () => import('@/entities/image/image-details.vue');

const Song = () => import('@/entities/song/song.vue');
const SongUpdate = () => import('@/entities/song/song-update.vue');
const SongDetails = () => import('@/entities/song/song-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'publication',
      name: 'Publication',
      component: Publication,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'publication/new',
      name: 'PublicationCreate',
      component: PublicationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'publication/:publicationId/edit',
      name: 'PublicationEdit',
      component: PublicationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'publication/:publicationId/view',
      name: 'PublicationView',
      component: PublicationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'image',
      name: 'Image',
      component: Image,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'image/new',
      name: 'ImageCreate',
      component: ImageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'image/:imageId/edit',
      name: 'ImageEdit',
      component: ImageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'image/:imageId/view',
      name: 'ImageView',
      component: ImageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'song',
      name: 'Song',
      component: Song,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'song/new',
      name: 'SongCreate',
      component: SongUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'song/:songId/edit',
      name: 'SongEdit',
      component: SongUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'song/:songId/view',
      name: 'SongView',
      component: SongDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
